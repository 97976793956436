import React from 'react';
import Carousel from './Carousel';

const Featured = () => {
    return (
        <div style={{position:'relative'}}>
        <Carousel/>
            
        </div>
    );
};

export default Featured;